import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Grid, MenuItem, TextField, Slide, DialogContent, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Response from '../components/Response';
import Api from '../configs/Api';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const formatNumber = (number) => {
    return new Intl.NumberFormat('id-ID').format(number);
};

const parseNumber = (formattedValue) => {
    return parseFloat(formattedValue.replace(/\./g, '').replace(',', '.')) || 0;
};

const UpdateForm = ({ open, onClose, title, forms, endpoint, getDatas, data, id }) => {
    const [formValues, setFormValues] = useState(data);
    const [response, setResponse] = useState([]);

    useEffect(() => {
        if (open) {
            if (data) {
                setFormValues(data);
            }
        }
    }, [open, data]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const res = await Api.Put(`/${endpoint}/${id}`, formValues);
            setResponse(res.data.data);
            getDatas();
            onClose();
        } catch (error) {
            setResponse(error.response.data.errors);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const field = forms.find((f) => f.name === name);

        if (field && field.type === 'number') {
            setFormValues((prev) => ({ ...prev, [name]: parseNumber(value) }));
        } else {
            setFormValues((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleDynamicArrayChange = (index, fieldName, value) => {
        const updatedArray = [...formValues[fieldName]];
        updatedArray[index] = value;
        setFormValues((prev) => ({ ...prev, [fieldName]: updatedArray }));
    };

    const handleAddDynamicItem = (fieldName) => {
        setFormValues((prev) => ({
            ...prev,
            [fieldName]: [...prev[fieldName], {}],
        }));
    };

    const handleRemoveDynamicItem = (fieldName, index) => {
        const updatedArray = formValues[fieldName].filter((_, i) => i !== index);
        setFormValues((prev) => ({ ...prev, [fieldName]: updatedArray }));
    };

    const handleNumberChange = (e, fieldName, index) => {
        const formattedValue = e.target.value;
        const unformattedValue = parseNumber(formattedValue);

        if (fieldName) {
            const updatedArray = [...formValues[fieldName]];
            updatedArray[index] = { ...updatedArray[index], [e.target.name]: unformattedValue };
            setFormValues((prev) => ({ ...prev, [fieldName]: updatedArray }));
        } else {
            setFormValues((prev) => ({
                ...prev,
                [e.target.name]: unformattedValue,
            }));
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
                TransitionComponent={Slide}
                transitionDuration={500}
            >
                <DialogTitle>UPDATE {title}</DialogTitle>
                <DialogContent>
                    <Box component={'form'} onSubmit={handleUpdate}>
                        {/* Render static fields */}
                        {forms?.filter(form => !form.forms).map((form) => (
                            <Grid mb={2} mt={2} key={form.name}>
                                <TextField
                                    label={form.label}
                                    name={form.name}
                                    value={form.type === 'number' ? formatNumber(formValues[form.name]) : formValues[form.name]}
                                    type={form.type === 'date' ? 'date' : 'text'}
                                    onChange={handleChange}
                                    select={form.select}
                                    disabled={form.disabled}
                                    fullWidth
                                >
                                    {form.select && form.options.map((o) => (
                                        <MenuItem key={o.id} value={o.id}>
                                            {o.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        ))}

                        {/* Render dynamic fields */}
                        {forms?.filter(form => form.forms).map((dynamicField) => (
                            <Box key={dynamicField.name}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="h6">{dynamicField.label}</Typography>
                                    <IconButton onClick={() => handleAddDynamicItem(dynamicField.name)} color="primary">
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                {Array.isArray(formValues[dynamicField.name]) && formValues[dynamicField.name].map((item, index) => (
                                    <Grid container spacing={2} key={index} alignItems="center" sx={{ mb: 2 }}>
                                        {dynamicField.forms.map((subField) => (
                                            <Grid item xs={subField.type === 'number' ? 3 : 5} key={subField.name}>
                                                <TextField
                                                    label={subField.label}
                                                    name={subField.name}
                                                    value={subField.type === 'number' ? formatNumber(item[subField.name] || 0) : item[subField.name] || ''}
                                                    onChange={(e) => subField.type === 'number'
                                                        ? handleNumberChange(e, dynamicField.name, index)
                                                        : handleDynamicArrayChange(index, dynamicField.name, { ...item, [subField.name]: e.target.value })}
                                                    select={subField.select}
                                                    disabled={subField.disable}
                                                    fullWidth
                                                >
                                                    {subField.select && subField.options.map((o) => (
                                                        <MenuItem key={o.id} value={o.id}>
                                                            {o.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        ))}
                                        <Grid item xs={1} sx={{ textAlign: 'right' }}>
                                            <IconButton onClick={() => handleRemoveDynamicItem(dynamicField.name, index)} color="secondary">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        ))}

                        <DialogActions sx={{ mt: 5}}>
                            <Button variant="outlined" color="primary" onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>

            <Response response={response} />
        </>
    );
};

export default UpdateForm;
