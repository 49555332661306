import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import _ from 'lodash'

export const Response = ({ response }) => {
    const [open, setOpen] = useState(false)
    const [sessionExpired, setSessionExpired] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (Array.isArray(response)) {
            if (response[0] === 'unauthorized') {
                setSessionExpired(true)
                setOpen(true)
            } else {
                for (let i = 0; i < response.length; i++) {
                    enqueueSnackbar(_(response[i]).upperFirst().replace('_', ' '), { variant: 'error', })
                }
            }
        } else {
            enqueueSnackbar('Success', { variant: 'success', color: 'dark' })
        }
    }, [response])

    const handleClose = () => {
        setOpen(false)
    }

    const handleRelogin = () => {
        sessionStorage.clear()
        navigate('/')
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Session Expired</DialogTitle>
                <DialogContent>
                    <Typography>Your session has expired. Please log in again.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="warning">
                        Cancel
                    </Button>
                    <Button onClick={handleRelogin} variant="contained" color="primary">
                        Log In
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Response
