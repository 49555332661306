import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import Login from './screens/Login'
import Main from './screens/Main'
import Register from './screens/Register'

const App = () => {
    return (
        <SnackbarProvider
            maxSnack={100}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/main' element={<Main />} />
                    <Route path='/register' element={<Register />} />
                </Routes>
            </BrowserRouter>
        </SnackbarProvider>
    )
}

export default App