import { useState, useEffect } from 'react'
import { Box,  Container, IconButton, Typography, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import Table from '../components/Table'
import CreateForm from '../components/CreateForm'
import UpdateForm from '../components/UpdateForm'
import DeleteForm from '../components/DeleteForm'
import Api from '../configs/Api'
import { PageContainer } from '@toolpad/core'

const Stock = () => {
    const [stocks, setStocks] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [warehouses, setWarehouses] = useState([])
    const [products, setProducts] = useState([])
    const [idToUpdate, setIdToUpdate] = useState(null)
    const [purchaseIdToUpdate, setPurchaseIdToUpdate] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)
    const [openCreateForm, setOpenCreateForm] = useState(null)
    const [openUpdateForm, setOpenUpdateForm] = useState(false)
    const [openDeleteForm, setOpenDeleteForm] = useState(false)
    const theme = useTheme()
    const paymentTypes = [
        { id: 'FULL', name: 'FULL' },
        { id: 'INSTALMENT', name: 'INSTALMENT' }
    ]
    
    const [data, setData] = useState({
        supplierId: '',
        warehouseId: '',
        paymentType: '',
        stocks: []
    })

    const [dataUpdate, setDataUpdate] = useState({
        productId: '',
        price: '',
        quantity: ''
    })

    const forms = [
        { label: 'Supplier', name: 'supplierId', select: true, options: suppliers },
        { label: 'Warehouse', name: 'warehouseId', select: true, options: warehouses },
        { label: 'Payment', name: 'paymentType', select: true, options: paymentTypes },
        {
            label: 'Product', name: 'stocks', forms: [
                { label: 'Product', name: 'productId', select: true, options: products },
                { label: 'Price', name: 'price', type: 'number' },
                { label: 'Quantity', name: 'quantity', type: 'number' }
            ]
        }
    ]

    const updateForms = [
        { label: 'Product', name: 'productId', select: true, options: products },
        { label: 'Price', name: 'price', type: 'number' },
        { label: 'Quantity', name: 'quantity', type: 'number' }
    ]

    const columns = [
        { field: 'code', headerName: 'Invoice ID', flex: 1 },
        { field: 'product', headerName: 'Product', flex: 1 },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => (
                'Rp ' + new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            flex: 1,
            renderCell: (params) => (
                new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'sell',
            headerName: 'Sell',
            flex: 1,
            renderCell: (params) => (
                new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'available',
            headerName: 'Available',
            flex: 1,
            renderCell: (params) => (
                new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton color="primary" onClick={() => handleOpenUpdateForm(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteForm(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ]

    const title = 'STOCK'
    const endpoint = '/stocks'

    useEffect(() => {
        getStocks()
    }, [])

    const getStocks = async () => {
        const res = await Api.Get(endpoint)
        setStocks(res.data.data)
    }

    const getSuppliers = async () => {
        const res = await Api.Get('/suppliers')
        setSuppliers(res.data.data)
    }

    const getWarehouses = async () => {
        const res = await Api.Get('/warehouses')
        setWarehouses(res.data.data)
    }

    const getProducts = async () => {
        const res = await Api.Get('/products')
        setProducts(res.data.data)
    }

    const handleOpenCreateForm = () => {
        setData({
            supplierId: '',
            warehouseId: '',
            paymentType: '',
            stocks: []
        })
        getSuppliers()
        getWarehouses()
        getProducts()
        setOpenCreateForm(true)
    }

    const handleCloseCreateForm = () => {
        setOpenCreateForm(false)
    }

    const handleOpenUpdateForm = (data) => {
        getProducts()
        setPurchaseIdToUpdate(data.purchaseId)
        setDataUpdate({
            productId: data.productId,
            price: data.price,
            quantity: data.quantity
        })
        setIdToUpdate(data.id)
        setOpenUpdateForm(true)
    }

    const handleCloseUpdateForm = () => setOpenUpdateForm(false)

    const handleOpenDeleteForm = (data) => {
        setIdToDelete(data.id)
        setOpenDeleteForm(true)
    }

    const handleCloseDeleteForm = () => setOpenDeleteForm(false)

    const handleRefreshData = () => {
        getStocks();
    }

    document.title = 'STOCK - ACCOUNTING - FXSTEEL'

    return (
        <PageContainer>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    width: '100%',
                }}
            >
                <Box></Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="primary"
                        onClick={handleRefreshData}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mr: 1 }}
                    >
                        <RefreshOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleOpenCreateForm}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>

            <CreateForm
                open={openCreateForm}
                onClose={handleCloseCreateForm}
                title={title}
                forms={forms}
                endpoint={'/purchases'}
                data={data}
                getDatas={getStocks}
            />

            <Table rows={stocks} columns={columns} />

            <DeleteForm
                open={openDeleteForm}
                onClose={handleCloseDeleteForm}
                title={title}
                endpoint={endpoint}
                id={idToDelete}
                getDatas={getStocks}
            />

            <UpdateForm
                open={openUpdateForm}
                onClose={handleCloseUpdateForm}
                title={title}
                forms={updateForms}
                endpoint={`purchases/${purchaseIdToUpdate}/stocks`}
                data={dataUpdate}
                id={idToUpdate}
                getDatas={getStocks}
            />
        </PageContainer>
    )
}

export default Stock