import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import Profile from './Profile';
import ACL from './ACL';
import ProductCategory from './ProductCategory';
import Product from './Product';
import Supplier from './Supplier';
import Account from './Account';
import Warehouse from './Warehouse';
import StockPayment from './StockPayment';
import Staff from './Staff';
import Position from './Position';
import Invesment from './Invesment';
import Stock from './Stock'
import Dashboard from './Dashboard';
import Order from './Order';
import OrderPayment from './OrderPayment';
import Investor from './Investor'
import Transaction from './Transaction';
import Customer from './Customer';
import Generic from './Generic';

import Api from '../configs/Api';

const demoTheme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: true },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

const componentMap = {
    dashboard: <Dashboard />,
    profile: <Profile />,
    acl: <ACL />,
    'master-data': {
        customer: <Customer/>,
        generic: <Generic/>,
        investor: <Investor />,
        position: <Position />,
        product: <Product />,
        'product-category': <ProductCategory />,
        supplier: <Supplier />,
        warehouse: <Warehouse />,
    },
    'human-resource': {
        staff: <Staff />,
    },
    inventory: {
        stock: <Stock />
    },
    sale: {
        order: <Order />
    },
    finance: {
        account: <Account />,
        invesment: <Invesment />,
        payment: {
            'stock-payment': <StockPayment />,
            'order-payment': <OrderPayment />,
            transaction: <Transaction />
        }
    }
};

function DemoPageContent({ pathname }) {
    const pathSegments = pathname.split('/').filter(Boolean);

    let currentComponent = componentMap

    for (const segment of pathSegments) {
        if (currentComponent && typeof currentComponent === 'object') {
            currentComponent = currentComponent[segment]
        } else {
            currentComponent = null; 
            break;
        }
    }

    return (
        <Box
            sx={{
                py: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            {currentComponent ? (
                React.isValidElement(currentComponent) ? (
                    currentComponent // Render the found component
                ) : (
                    <Typography>Please specify a more detailed path.</Typography>
                )
            ) : (
                <Typography>No content available for {pathname}</Typography>
            )}
        </Box>
    );
}

DemoPageContent.propTypes = {
    pathname: PropTypes.string.isRequired,
};

function DashboardLayoutNavigationDividers(props) {
    const { window } = props;

    const [pathname, setPathname] = React.useState('/profile');
    const [session, setSession] = React.useState({
        user: {
            name: sessionStorage.getItem('user')
        },
    })
    const [response, setResponse] = React.useState([])

    const router = React.useMemo(() => ({
        pathname,
        searchParams: new URLSearchParams(),
        navigate: (path) => setPathname(path), // Update pathname directly
    }), [pathname]);

    const demoWindow = window !== undefined ? window() : undefined;

    const authentication = React.useMemo(() => {
        return {
            signIn: () => {
                setSession({
                    user: {
                        name: sessionStorage.getItem('user')
                    },
                });
            },
            signOut: () => {
                setSession(null);
                handleLogout()
            },
        };
    }, []);

    const n = useNavigate()

    const handleLogout = async () => {
        try {
            const res = await Api.Post(`/users/${session.user.name}/logout`)
            setResponse(res.data.data)
            sessionStorage.clear()
            n('/')
        } catch (error) {
            setResponse(error.response.data.errors)
        }
    };

    return (
        <AppProvider
            navigation={[
                // { segment: 'dashboard', title: 'Dashboard' },
                // { kind: 'divider' },
                { segment: 'profile', title: 'Profile' },
                ...(session?.user?.name === 'ucupxamir' ? [
                    { segment: 'acl', title: 'Access Control List' },
                ] : []),
                { kind: 'divider' },
                {
                    segment: 'master-data',
                    title: 'Master Data',
                    children: [
                        { segment: 'customer', title: 'Customer' },
                        ...(session?.user?.name === 'ucupxamir' ? [
                            { segment: 'generic', title: 'Generic' },
                        ] : []),
                        { segment: 'investor', title: 'Investor' },
                        { segment: 'position', title: 'Position' },
                        { segment: 'product', title: 'Product' },
                        { segment: 'product-category', title: 'Product Category' },
                        { segment: 'supplier', title: 'Supplier' },
                        { segment: 'warehouse', title: 'Warehouse' },
                    ]
                },
                {
                    segment: 'human-resource',
                    title: 'Human Resource',
                    children: [
                        { segment: 'staff', title: 'Staff' },
                    ]
                },
                {
                    segment: 'inventory',
                    title: 'Inventory',
                    children: [
                        { segment: 'stock', title: 'Stock' },
                    ]
                },
                {
                    segment: 'sale',
                    title: 'Sales',
                    children: [
                        { segment: 'order', title: 'Order' },
                    ]
                },
                {
                    segment: 'finance',
                    title: 'Finance',
                    children: [
                        { segment: 'account', title: 'Account' },
                        { segment: 'invesment', title: 'Invesment' },
                        { 
                            segment: 'payment', 
                            title: 'Payment',
                            children: [
                                { segment: 'stock-payment', title: 'Stock' },
                                { segment: 'order-payment', title: 'Order' },
                                { segment: 'transaction', title: 'Transaction' }
                            ] 
                        }
                    ]
                }
            ]}
            router={router}
            theme={demoTheme}
            window={demoWindow}
            authentication={authentication}
            session={session}
            branding={{
                title: 'FXSTEEL',
            }}
        >
            <DashboardLayout>
                <DemoPageContent pathname={pathname} />
            </DashboardLayout>
        </AppProvider>
    );
}

DashboardLayoutNavigationDividers.propTypes = {
    window: PropTypes.func,
};

export default DashboardLayoutNavigationDividers;
