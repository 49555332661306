import React, { useEffect, useState } from 'react';
import { Container, Card, CardContent, Typography, Avatar, Grid, Button, Divider, Box, useTheme } from '@mui/material';
import Api from '../configs/Api';
import Response from '../components/Response';
import { PageContainer } from '@toolpad/core';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        try {
            const res = await Api.Get(`/users/${sessionStorage.getItem('user')}`);
            setUser(res.data.data);
        } catch (error) {
            setResponse(error.response.data.errors);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Typography>Loading...</Typography>
            </Container>
        );
    }

    if (!user) {
        return (
            <Container>
                <Typography variant="h6" color="textSecondary">No user data found</Typography>
                <Response response={response} />
            </Container>
        );
    }

    document.title = 'PROFILE - FXSTEEL'

    return (
        <PageContainer>
              <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={4} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Avatar
                                alt={user.name}
                                src={user.avatarUrl}
                                sx={{
                                    width: 100,
                                    height: 100,
                                    border: `2px solid ${theme.palette.primary.main}`,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={9}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{user.name}</Typography>
                            <Typography variant="subtitle1" color="textSecondary">@{user.username}</Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="body1" color="textSecondary">
                                <a href={`mailto:${user.email}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                                    {user.email}
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Response response={response} />
        </PageContainer>
    );
};

export default Profile;