import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Typography, useTheme, DialogContent, Grid, IconButton, DialogActions, Button, TextField, Slide, Box, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../configs/Api';
import Response from './Response';

const CreateForm = ({ open, onClose, title, forms, endpoint, data, getDatas }) => {
    const [formData, setFormData] = useState({});
    const [response, setResponse] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        setFormData(data)
    }, [open])

    const formatNumber = (value) => {
        return new Intl.NumberFormat('id-ID').format(value);
    };

    const parseNumber = (value) => {
        return parseInt(value.replace(/\./g, ''), 10) || 0;
    };

    const handleChange = ({ target: { name, value } }) => {
        const field = forms.find((f) => f.name === name);

        if (field && field.type === 'number') {
            setFormData((prev) => ({ ...prev, [name]: parseNumber(value) }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleDynamicArrayChange = (index, fieldName, subFieldName, value) => {
        const updatedArray = [...formData[fieldName]];
        if (forms.find(f => f.name === fieldName).forms.find(sub => sub.name === subFieldName).type === 'number') {
            updatedArray[index] = { ...updatedArray[index], [subFieldName]: parseNumber(value) };
        } else {
            updatedArray[index] = { ...updatedArray[index], [subFieldName]: value };
        }
        setFormData((prev) => ({ ...prev, [fieldName]: updatedArray }));
    };

    const handleAddDynamicItem = (fieldName) => {
        setFormData((prev) => ({ ...prev, [fieldName]: [...prev[fieldName], {}] }));
    };

    const handleRemoveDynamicItem = (fieldName, index) => {
        const updatedArray = formData[fieldName].filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, [fieldName]: updatedArray }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSubmit = Object.keys(formData).reduce((acc, key) => {
            if (Array.isArray(formData[key])) {
                acc[key] = formData[key].map(item => {
                    const processedItem = { ...item };
                    Object.keys(item).forEach(subKey => {
                        if (typeof item[subKey] === 'string' && item[subKey].includes('.')) {
                            processedItem[subKey] = parseNumber(item[subKey]);
                        } else if (typeof item[subKey] === 'number') {
                            processedItem[subKey] = item[subKey];
                        } else {
                            processedItem[subKey] = item[subKey] || '';
                        }
                    });
                    return processedItem;
                });
            } else {
                // Process main form field
                if (typeof formData[key] === 'string' && formData[key].includes('.')) {
                    acc[key] = parseNumber(formData[key]);
                } else if (typeof formData[key] === 'number') {
                    acc[key] = formData[key];
                } else {
                    acc[key] = formData[key] || '';
                }
            }
            return acc;
        }, {});

        try {
            const res = await Api.Post(endpoint, dataToSubmit);
            setResponse(res.data.data);
            getDatas();
            onClose();
            setFormData(data); // Reset form data if needed
        } catch (error) {
            setResponse(error.response.data.errors);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
                TransitionComponent={Slide}
                transitionDuration={500}
            >
                <DialogTitle>CREATE {title}</DialogTitle>
                <DialogContent>
                    <Box component={'form'} onSubmit={handleSubmit}>
                        {/* Render fields biasa */}
                        {forms.filter(f => !f.forms).map((form) => (
                            <Grid mb={2} mt={2} key={form.name}>
                                <TextField
                                    label={form.label}
                                    name={form.name}
                                    value={form.type === 'number' ? formatNumber(formData[form.name]) : formData[form.name]}
                                    onChange={handleChange}
                                    select={form.select}
                                    disabled={form.disabled}
                                    type={form.type === 'date' ? 'date' : 'text'}
                                    fullWidth
                                    shrunk={true}
                                >
                                    {form.select && form.options.map((o) => (
                                        <MenuItem key={o.id} value={o.id}>
                                            {o.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        ))}

                        {forms.filter(f => f.forms).map((dynamicField) => (
                            <Box key={dynamicField.name}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="h6">{dynamicField.label}</Typography>
                                    <IconButton
                                        onClick={() => handleAddDynamicItem(dynamicField.name)}
                                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                {formData[dynamicField.name]?.map((item, index) => (
                                    <Grid container spacing={2} key={index} alignItems="center" sx={{ mb: 2 }}>
                                        {dynamicField.forms.map((subField) => (
                                            <Grid item xs={subField.type === 'number' ? 3 : 5} key={subField.name}>
                                                <TextField
                                                    label={subField.label}
                                                    name={subField.name}
                                                    value={subField.type === 'number' ? formatNumber(item[subField.name] || 0) : item[subField.name] || ''}
                                                    onChange={(e) => handleDynamicArrayChange(index, dynamicField.name, subField.name, e.target.value)}
                                                    select={subField.select}
                                                    fullWidth
                                                >
                                                    {subField.select && subField.options.map((o) => (
                                                        <MenuItem key={o.id} value={o.id}>
                                                            {o.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        ))}
                                        <Grid item xs={1} sx={{ textAlign: 'right' }}>
                                            <IconButton onClick={() => handleRemoveDynamicItem(dynamicField.name, index)} color="secondary">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        ))}

                        <DialogActions sx={{ mt: 5}}>
                            <Button variant="outlined" color="primary" onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>

            <Response response={response} />
        </>
    );
};

export default CreateForm;
