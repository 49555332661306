import { useEffect, useState } from 'react'
import { LineChart } from '@mui/x-charts/LineChart'
import CircularProgress from '@mui/material/CircularProgress';
import Api from '../configs/Api'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, Grid2, Typography } from '@mui/material'
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { PageContainer } from '@toolpad/core';

const Dashboard = () => {
    const [charts, setCharts] = useState([])
    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
    }, [loading])

    const getData = async () => {
        const res = await Api.Get('/dashboards')
        setCharts(res.data.data.charts)
        setCards(res.data.data.cards)
        setLoading(false)
    }

    if (loading) {
        return (
            <CircularProgress size="3rem" />
        )
    }

    document.title = 'DASHBOARD - FXSTEEL'

    return (
        <PageContainer>
            <Box>
                <Grid2 container spacing={2}>
                    {cards.map((x) => (
                        <Grid2 size={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5'>{x.name}</Typography>
                                    <Typography variant='h6' sx={{ mt: 5 }}>Rp {new Intl.NumberFormat('id-ID').format(x.total)}</Typography>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))}
                    {charts.map((x) => (
                        <Grid2 size={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5'>{x.name}</Typography>
                                    <Divider sx={{ mt: 2, mb: 3 }} />
                                    <LineChart
                                        xAxis={[{ scaleType: 'point', data: x.charts.xAxis }]}
                                        series={[{ data: x.charts.series }]}
                                        margin={{ top: 5, right: 20, bottom: 50, left: 80 }}
                                        height={300}

                                    />
                                </CardContent>
                            </Card>

                        </Grid2>
                    ))}
                </Grid2>
            </Box>
        </PageContainer>
    )
}

export default Dashboard