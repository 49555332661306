import { Box, IconButton, useTheme, Grid, Dialog, DialogContent, Slide, Typography, Card, CardContent, Divider } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import Table from "../components/Table"
import CreateForm from '../components/CreateForm'
import UpdateForm from '../components/UpdateForm'
import DeleteForm from '../components/DeleteForm'
import moment from 'moment'
import { useEffect, useState } from "react"
import Api from "../configs/Api"

const OrderPaymentDetail = ({ open, onClose, payment, getDatas }) => {
    const [payments, setPayments] = useState([])
    const [accounts, setAccounts] = useState([])
    const [idToUpdate, setIdToUpdate] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)
    const [openCreateForm, setOpenCreateForm] = useState(null)
    const [openUpdateForm, setOpenUpdateForm] = useState(false)
    const [openDeleteForm, setOpenDeleteForm] = useState(false)
    const theme = useTheme()

    const [data, setData] = useState({
        accountId: '',
        amount: ''
    })

    const [forms, setForms] = useState([])

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => (
                moment(params.value).format('DD MMMM YYYY')
            )
        },
        { field: 'account', headerName: 'Account', flex: 1 },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            renderCell: (params) => (
                `Rp ${new Intl.NumberFormat('id-ID').format(params.value)}`
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton color="primary" onClick={() => handleOpenUpdateForm(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteForm(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ]

    const title = 'PAYMENT'

    useEffect(() => {
        if (open) {
            if (payment) {
                getPayments()
                getAccounts()
            }
        }
    }, [open, payment])

    const getPayments = async () => {
        const res = await Api.Get(`/orders/${payment?.id}/payments`)
        setPayments(res.data.data)
    }

    const getAccounts = async () => {
        const res = await Api.Get('/accounts')
        setAccounts(res.data.data)
    }

    const handleOpenCreateForm = () => {
        setData({
            accountId: '',
            amount: payment?.paymentType === 'FULL' ? payment.totalPrice : ''
        })
        setForms([
            { label: 'Account', name: 'accountId', select: true, options: accounts },
            { label: 'Amount', name: 'amount', type: 'number', disabled: payment?.paymentType === 'FULL' }
        ])
        setOpenCreateForm(true)
    }

    const handleCloseCreateForm = () => {
        getDatas()
        setOpenCreateForm(false)
        onClose()
    }

    const handleOpenUpdateForm = (data) => {
        setForms([
            { label: 'Account', name: 'accountId', select: true, options: accounts },
            { label: 'Amount', name: 'amount', type: 'number', disabled: payment?.paymentType === 'FULL' }
        ])
        setData({
            accountId: data.accountId,
            amount: data.amount
        })
        setIdToUpdate(data.id)
        setOpenUpdateForm(true)
    }

    const handleCloseUpdateForm = () => setOpenUpdateForm(false)

    const handleOpenDeleteForm = (data) => {
        setIdToDelete(data.id)
        setOpenDeleteForm(true)
    }

    const handleCloseDeleteForm = () => setOpenDeleteForm(false)

    const handleRefreshData = () => {
        getPayments()
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
                TransitionComponent={Slide}
                transitionDuration={500}
            >
                <DialogContent>
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{ textAlign: 'center', mb: 3 }}>
                                    <Typography variant="h4">{payment?.code}</Typography>
                                    <Typography variant="h7">{moment(payment?.createdAt).format('DD MMMM YYYY')}</Typography>
                                    <Divider sx={{ mt: 3}}/>
                                </Grid>
                                <Grid item xs={4} container textAlign="left">
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Name</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1">: &nbsp; {payment?.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container textAlign="left">
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Staff</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1">: &nbsp; {payment?.staff}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container textAlign="left">
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Payment</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1">: &nbsp; {payment?.paymentType}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container textAlign="left">
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Total Price</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1">: &nbsp; Rp {new Intl.NumberFormat('id-ID').format(payment?.totalPrice)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container textAlign="left">
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Total Paid</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1">: &nbsp; Rp {new Intl.NumberFormat('id-ID').format(payment?.totalPaid)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container alignItems="left">
                                    <Grid item xs={3}>
                                        <Typography variant="body1">Remainings</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1">: &nbsp; Rp {new Intl.NumberFormat('id-ID').format(payment?.remainings)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                            width: '100%',
                        }}
                    >
                        <Typography variant="h6">{title}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                color="primary"
                                onClick={handleRefreshData}
                                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mr: 1 }}
                            >
                                <RefreshOutlinedIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleOpenCreateForm}
                                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Table rows={payments} columns={columns} />
                </DialogContent>
            </Dialog>

            <CreateForm
                open={openCreateForm}
                onClose={handleCloseCreateForm}
                title={title}
                forms={forms}
                endpoint={`/orders/${payment?.id}/payments`}
                data={data}
                getDatas={getPayments}
            />

            <UpdateForm
                open={openUpdateForm}
                onClose={handleCloseUpdateForm}
                title={title}
                forms={forms}
                endpoint={`/orders/${payment?.id}/payments`}
                data={data}
                id={idToUpdate}
                getDatas={getPayments}
            />

            <DeleteForm
                open={openDeleteForm}
                onClose={handleCloseDeleteForm}
                title={title}
                endpoint={`/orders/${payment?.id}/payments`}
                id={idToDelete}
                getDatas={getPayments}
            />
        </>

    )
}

export default OrderPaymentDetail