import { useState, useEffect } from 'react'
import { Box,  Container, IconButton, Typography, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import Table from '../components/Table'
import CreateForm from '../components/CreateForm'
import UpdateForm from '../components/UpdateForm'
import DeleteForm from '../components/DeleteForm'
import Api from '../configs/Api'
import moment from 'moment'
import { PageContainer } from '@toolpad/core'

const Order = () => {
    const [orders, setOrders] = useState([])
    const [customers, setCustomers] = useState([])
    const [staffs, setStaffs] = useState([])
    const [stocks, setStocks] = useState([])
    const [idToUpdate, setIdToUpdate] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)
    const [openCreateForm, setOpenCreateForm] = useState(null)
    const [openUpdateForm, setOpenUpdateForm] = useState(false)
    const [openDeleteForm, setOpenDeleteForm] = useState(false)
    const theme = useTheme()

    const paymentTypes = [
        { id: 'FULL', name: 'FULL' },
        { id: 'INSTALMENT', name: 'INSTALMENT' }
    ]
    
    const [data, setData] = useState({
        customerId: '',
        staffId: '',
        paymentType: '',
        orderStocks: []
    })

    const forms = [
        { label: 'Customer', name: 'customerId', select: true, options: customers },
        { label: 'Sales', name: 'staffId', select: true, options: staffs },
        { label: 'Payment', name: 'paymentType', select: true, options: paymentTypes },
        {
            label: 'Product', name: 'orderStocks', forms: [
                { label: 'Product', name: 'stockId', select: true, options: stocks },
                { label: 'Price', name: 'price', type: 'number' },
                { label: 'Quantity', name: 'quantity', type: 'number' }
            ]
        }
    ]

    const columns = [
        { field: 'code', headerName: 'Order ID', flex: 1 },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => (
                moment(params.value).format('DD MMMM YYYY')
            )
        },
        { field: 'customer', headerName: 'Customer', flex: 1 },
        { field: 'staff', headerName: 'Sales', flex: 1 },
        { field: 'paymentType', headerName: 'Payment', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton color="primary" onClick={() => handleOpenUpdateForm(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteForm(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ]

    const title = 'ORDER'
    const endpoint = '/orders'

    useEffect(() => {
        getOrders()
    }, [])

    const getOrders = async () => {
        const res = await Api.Get(endpoint)
        setOrders(res.data.data)
    }

    const getStaffs = async () => {
        const res = await Api.Get('/staffs')
        setStaffs(res.data.data)
    }

    const getCustomers = async () => {
        const res = await Api.Get('/customers')
        setCustomers(res.data.data)
    }

    const getStocks = async () => {
        const res = await Api.Get('/stocks')
        setStocks(res.data.data)
    }

    const handleOpenCreateForm = () => {
        setData({
            customerId: '',
            staffId: '',
            paymentType: '',
            orderStocks: []
        })
        getCustomers()
        getStaffs()
        getStocks()
        setOpenCreateForm(true)
    }

    const handleCloseCreateForm = () => setOpenCreateForm(false)

    const handleOpenUpdateForm = (data) => {
        getCustomers()
        getStaffs()
        getStocks()
        setIdToUpdate(data.id)
        setData({
            customerId: data.customerId,
            staffId: data.staffId,
            paymentType: data.paymentType,
            orderStocks: data.orderStocks.map(x => ({
                stockId: x.stockId,
                price: x.price,
                quantity: x.quantity
            }))
        })
        setOpenUpdateForm(true)
    }

    const handleCloseUpdateForm = () => setOpenUpdateForm(false)

    const handleOpenDeleteForm = (data) => {
        setIdToDelete(data.id)
        setOpenDeleteForm(true)
    }

    const handleCloseDeleteForm = () => setOpenDeleteForm(false)

    const handleRefreshData = () => {
        getOrders();
    }

    document.title = 'ORDER - SALE - FXSTEEL'

    return (
        <PageContainer>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    width: '100%',
                }}
            >
                <Box></Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="primary"
                        onClick={handleRefreshData}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mr: 1 }}
                    >
                        <RefreshOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleOpenCreateForm}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>

            <CreateForm
                open={openCreateForm}
                onClose={handleCloseCreateForm}
                title={title}
                forms={forms}
                endpoint={endpoint}
                data={data}
                getDatas={getOrders}
            />

            <Table rows={orders} columns={columns} />

            <DeleteForm
                open={openDeleteForm}
                onClose={handleCloseDeleteForm}
                title={title}
                endpoint={endpoint}
                id={idToDelete}
                getDatas={getOrders}
            />

            <UpdateForm
                open={openUpdateForm}
                onClose={handleCloseUpdateForm}
                title={title}
                forms={forms}
                endpoint={endpoint}
                data={data}
                id={idToUpdate}
                getDatas={getOrders}
            />
        </PageContainer>
    )
}

export default Order