import { useEffect, useState } from "react"
import { Box, IconButton, useTheme } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import Table from "../components/Table"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import DeleteForm from "../components/DeleteForm"
import OrderPaymentDetail from "../dialogs/OrderPaymentDetail"

import Api from "../configs/Api"
import { PageContainer } from "@toolpad/core"

const OrderPayment = () => {
    const [orders, setOrders] = useState([])
    const [idToDelete, setIdToDelete] = useState(null)
    const [detailData, setDetailData] = useState({})
    const [openDetailForm, setOpenDetailForm] = useState(false)
    const [openDeleteForm, setOpenDeleteForm] = useState(false)
    const theme = useTheme();

    const columns = [
        { field: 'code', headerName: 'Order ID', flex: 1 },
        { field: 'date', headerName: 'Date', flex: 1, },
        { field: 'customer', headerName: 'Customer', flex: 1 },
        { field: 'staff', headerName: 'Staff', flex: 1 },
        { field: 'paymentType', headerName: 'Payment', flex: 1 },
        {
            field: 'totalPrice',
            headerName: 'Total Price',
            flex: 1,
            renderCell: (params) => (
                `Rp ${new Intl.NumberFormat('id-ID').format(params.value)}`
            )
        },
        {
            field: 'totalPaid',
            headerName: 'Paid',
            flex: 1,
            renderCell: (params) => (
                `Rp ${new Intl.NumberFormat('id-ID').format(params.value)}`
            )
        },
        {
            field: 'remainings',
            headerName: 'Remainings',
            flex: 1,
            renderCell: (params) => (
                `Rp ${new Intl.NumberFormat('id-ID').format(params.value)}`
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleOpenDetailForm(params.row)}>
                        <InfoIcon />
                    </IconButton>
                    {/* <IconButton color="error" onClick={() => handleOpenDeleteForm(params.row)}>
                        <DeleteIcon />
                    </IconButton> */}
                </>
            )
        }
    ]

    const title = 'ORDER PAYMENT'
    const endpoint = '/orders'

    useEffect(() => {
        getOrders()
    }, [])

    const getOrders = async () => {
        const res = await Api.Get(endpoint)
        setOrders(res.data.data)
    }

    const handleOpenDetailForm = (data) => {
        setDetailData(data)
        setOpenDetailForm(true)
    }

    const handleCloseDetailForm = () => setOpenDetailForm(false)

    const handleOpenDeleteForm = (data) => {
        setIdToDelete(data.id)
        setOpenDeleteForm(true)
    }

    const handleCloseDeleteForm = () => setOpenDeleteForm(false)

    const handleRefreshData = () => {
        getOrders();
    }

    document.title = 'ORDER - PAYMENT - FINANCE - FXSTEEL'

    return (
        <PageContainer fluid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    width: '100%',
                }}
            >
                <Box></Box>
                <Box sx={{ display: 'flex', alignItems: 'right' }}>
                    <IconButton
                        color="primary"
                        onClick={handleRefreshData}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mr: 1 }}
                    >
                        <RefreshOutlinedIcon />
                    </IconButton>
                </Box>
            </Box>

            <Table rows={orders} columns={columns} />

            <DeleteForm
                open={openDeleteForm}
                onClose={handleCloseDeleteForm}
                title={title}
                endpoint={endpoint}
                id={idToDelete}
                getDatas={getOrders}
            />

            <OrderPaymentDetail open={openDetailForm} onClose={handleCloseDetailForm} payment={detailData} getDatas={getOrders} />
        </PageContainer>
    )
}

export default OrderPayment