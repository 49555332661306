import { useState, useEffect } from 'react'
import { Box, Container, IconButton, Typography, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import Table from '../components/Table'
import CreateForm from '../components/CreateForm'
import UpdateForm from '../components/UpdateForm'
import DeleteForm from '../components/DeleteForm'
import Api from '../configs/Api'
import moment from 'moment'
import { PageContainer } from '@toolpad/core'
import InvesmentDetail from '../dialogs/InvesmentDetail'

const Invesment = () => {
    const [invesments, setInvesments] = useState([])
    const [detailData, setDetailData] = useState({})
    const [accounts, setAccounts] = useState([])
    const [investors, setInvestors] = useState([])
    const [idToUpdate, setIdToUpdate] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)
    const [openCreateForm, setOpenCreateForm] = useState(null)
    const [openDetailForm, setOpenDetailForm] = useState(false)
    const [openUpdateForm, setOpenUpdateForm] = useState(false)
    const [openDeleteForm, setOpenDeleteForm] = useState(false)
    const theme = useTheme()

    const [data, setData] = useState({
        date: '',
        investorId: '',
        accountId: '',
        amount: '',
        benefit: '',
        dueDate: '',
        description: ''
    })

    const [forms, setForms] = useState([])

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => (
                moment(params.value).format('DD MMM YY')
            )
        },
        { field: 'investor', headerName: 'Investor', flex: 1 },
        { field: 'account', headerName: 'Account', flex: 1 },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            renderCell: (params) => (
                'Rp ' + new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'benefit',
            headerName: 'Benefit',
            flex: 1,
            renderCell: (params) => (
                'Rp ' + new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'totalPaid',
            headerName: 'Paid',
            flex: 1,
            renderCell: (params) => (
                'Rp ' + new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'remainings',
            headerName: 'Remainings',
            flex: 1,
            renderCell: (params) => (
                'Rp ' + new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            flex: 1,
            renderCell: (params) => (
                moment(params.value).format('DD MMM YY')
            )
        },
        { field: 'description', headerName: 'Description', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                      <IconButton onClick={() => handleOpenDetailForm(params.row)}>
                        <InfoIcon />
                    </IconButton>
                    <IconButton color="primary" onClick={() => handleOpenUpdateForm(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteForm(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ];

    const title = 'INVESMENT'
    const endpoint = '/invesments'

    useEffect(() => {
        getInvesments()
        getAccounts()
        getInvestors()
    }, [])

    const getInvesments = async () => {
        const res = await Api.Get(endpoint)
        setInvesments(res.data.data)
    }

    const getAccounts = async () => {
        const res = await Api.Get('/accounts')
        setAccounts(res.data.data)
    }

    const getInvestors = async () => {
        const res = await Api.Get('/investors')
        setInvestors(res.data.data)
    }

    const handleOpenCreateForm = () => {
        setData({
            date: moment(),
            investorId: '',
            accountId: '',
            amount: '',
            benefit: '0',
            dueDate: moment(),
            description: ''
        })
        setForms([
            { label: 'Date', name: 'date', type: 'date' },
            { label: 'Investor', name: 'investorId', select: true, options: investors },
            { label: 'Account', name: 'accountId', select: true, options: accounts },
            { label: 'Amount', name: 'amount', type: 'number' },
            { label: 'Benefit', name: 'benefit', type: 'number' },
            { label: 'Due Date', name: 'dueDate', type: 'date' },
            { label: 'Description', name: 'description' }
        ])
        setOpenCreateForm(true)
    }

    const handleCloseCreateForm = () => setOpenCreateForm(false)

    const handleOpenDetailForm = (data) => {
        setDetailData(data)
        setOpenDetailForm(true)
    }

    const handleCloseDetailForm = () => setOpenDetailForm(false)

    const handleOpenUpdateForm = (data) => {
        setForms([
            { label: 'Date', name: 'date', type: 'date' },
            { label: 'Investor', name: 'investorId', select: true, options: investors },
            { label: 'Account', name: 'accountId', select: true, options: accounts, disabled: true },
            { label: 'Amount', name: 'amount', type: 'number' },
            { label: 'Benefit', name: 'benefit', type: 'number' },
            { label: 'Due Date', name: 'dueDate', type: 'date' },
            { label: 'Description', name: 'description' }
        ])
        setData({
            date: data.date,
            investorId: data.investorId,
            accountId: data.accountId,
            amount: data.amount,
            benefit: data.benefit,
            dueDate: data.dueDate,
            description: data.description
        })
        setIdToUpdate(data.id)
        setOpenUpdateForm(true)
    }

    const handleCloseUpdateForm = () => setOpenUpdateForm(false)

    const handleOpenDeleteForm = (data) => {
        setIdToDelete(data.id)
        setOpenDeleteForm(true)
    }

    const handleCloseDeleteForm = () => setOpenDeleteForm(false)

    const handleRefreshData = () => {
        getInvesments()
    }

    document.title = 'INVESMENT - ACCOUNTING - FXSTEEL'

    return (
        <PageContainer>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    width: '100%',
                }}
            >
                <Box></Box> 
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="primary"
                        onClick={handleRefreshData}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mr: 1 }}
                    >
                        <RefreshOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleOpenCreateForm}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>

            <CreateForm
                open={openCreateForm}
                onClose={handleCloseCreateForm}
                title={title}
                forms={forms}
                endpoint={endpoint}
                data={data}
                getDatas={getInvesments}
            />

            <Table rows={invesments} columns={columns} />

            <DeleteForm
                open={openDeleteForm}
                onClose={handleCloseDeleteForm}
                title={title}
                endpoint={endpoint}
                id={idToDelete}
                getDatas={getInvesments}
            />

            <UpdateForm
                open={openUpdateForm}
                onClose={handleCloseUpdateForm}
                title={title}
                forms={forms}
                endpoint={endpoint}
                data={data}
                id={idToUpdate}
                getDatas={getInvesments}
            />

            <InvesmentDetail open={openDetailForm} onClose={handleCloseDetailForm} invesment={detailData} />
        </PageContainer>
    )
}

export default Invesment