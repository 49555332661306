import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, InputAdornment, IconButton, useTheme, useMediaQuery } from '@mui/material'
import { keyframes } from '@mui/system'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Response from '../components/Response'
import axios from 'axios'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }`

const Login = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' })
    const [showPassword, setShowPassword] = useState(false)
    const [response, setResponse] = useState([])
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        if (sessionStorage.getItem('token') !== null) {
            navigate('/main')
        }
    }, [navigate])

    const handleClickShowPassword = () => setShowPassword(prev => !prev)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {}, {
                auth: credentials
            });
            sessionStorage.setItem('token', res.data.data.token)
            sessionStorage.setItem('user', credentials.username)
            setResponse("Successfully login")
            setTimeout(() => {
                navigate('/main')
            }, 1000)
        } catch (error) {
            setResponse(error.response?.data.errors || ['An error occurred'])
        }
    }

    document.title = 'FXSTEEL'

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: `url('/bg-img.jpg') no-repeat center center fixed`,
                backgroundSize: 'cover',
                overflow: 'hidden',
            }}
        >
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    borderRadius: 2,
                    boxShadow: 3,
                    padding: 3,
                    width: isMobile ? '90%' : '400px',
                    animation: `${fadeIn} 0.8s ease-out`,
                    backdropFilter: 'none',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.1)' } }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#000' }}>
                    Sign In
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            value={credentials.username}
                            onChange={handleChange}
                            autoComplete="username"
                            autoFocus
                            sx={{ animation: `${fadeIn} 1s ease-out` }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ animation: `${fadeIn} 1s ease-out` }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, animation: `${fadeIn} 1.2s ease-out`, backgroundColor: theme.palette.primary.main }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgot-password" variant="body2">
                                    Forgot Password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
            <Response response={response} />
        </Box>
    )
}

export default Login