import axios from 'axios'

const BaseURL = process.env.REACT_APP_BACKEND_URL

class Api {
    Token () {
        return sessionStorage.getItem('token')
    }

    Headers () {
        return {
            headers: {
                Authorization: 'Bearer ' + this.Token()
            }
        }
    }
    Get (endpoint) {
        return axios.get(BaseURL + endpoint, this.Headers())
    }

    Post (endpoint, request) {
        return axios.post(BaseURL + endpoint, request, this.Headers())
    }

    Put (endpoint, request) {
        return axios.put(BaseURL + endpoint, request, this.Headers())
    }

    Delete (endpoint, id) {
        return axios.delete(BaseURL + endpoint + '/' + id, this.Headers())
    }
}

export default new Api()