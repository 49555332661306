import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import Api from '../configs/Api';
import { PageContainer } from '@toolpad/core';

const ACL = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        const res = await Api.Get('/users');
        setUsers(res.data.data);
    };

    const handleActivation = async (username, isActive) => {
        const payload = { isActive: !isActive };
        await Api.Put(`/users/${username}/activation`, payload);
        getUsers();
    };

    const userColumns = [
        { field: 'username', headerName: 'Username', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'isActive',
            headerName: 'Active',
            flex: 1,
            renderCell: (params) => (
                params.value ? (
                    <CheckIcon color="primary" />
                ) : (
                    <ClearIcon color="error" />
                )
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color={params.row.isActive ? 'error' : 'success'}
                    onClick={() => handleActivation(params.row.username, params.row.isActive)}
                >
                    {params.row.isActive ? 'Deactivate' : 'Activate'}
                </Button>
            ),
        },
    ];

    document.title = 'ACCESS CONTROL LIST - FXSTEEL';

    function getRowId(row) {
        return row.username;
    }

    return (
        <PageContainer>
            <DataGrid
                rows={users}
                columns={userColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                getRowId={getRowId}
                pageSizeOptions={[10]}
            />
        </PageContainer>
       
    );
};

export default ACL;
