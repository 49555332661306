import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Response from '../components/Response';
import { useNavigate } from 'react-router-dom';
import Api from '../configs/Api';
import { keyframes } from '@mui/system';
import Link from '@mui/material/Link';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Register = () => {
    const [user, setUser] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        email: '',
    });
    const [response, setResponse] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'confirmPassword') {
            setPasswordError(value !== user.password ? 'Passwords do not match' : '');
        }
    };

    const handleClickShowPassword = () => setShowPassword(prev => !prev);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword(prev => !prev);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user.password !== user.confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }
        try {
            const res = await Api.Post('/register', {
                username: user.username,
                password: user.password,
                name: user.name,
                email: user.email,
            })
            setResponse(res.data.data)
            setTimeout(() => {
                navigate('/')
            }, 2000)
        } catch (error) {
            console.error(error.response.data.errors)
            setResponse(error.response.data?.errors)
        }
    };

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                backgroundImage: 'url(/bg-img.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <CssBaseline />
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#fff', // Ensure the form background is solid white
                        borderRadius: 2,
                        boxShadow: 3,
                        padding: 3,
                        width: '100%',
                        maxWidth: '400px',
                        animation: `${fadeIn} 0.8s ease-out`,
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.1)' } }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                        Register
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                value={user.username}
                                onChange={handleChange}
                                autoComplete="username"
                                autoFocus
                                sx={{ animation: `${fadeIn} 1s ease-out` }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                value={user.password}
                                onChange={handleChange}
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ animation: `${fadeIn} 1s ease-out` }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                value={user.confirmPassword}
                                onChange={handleChange}
                                label="Confirm Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ animation: `${fadeIn} 1s ease-out` }}
                            />
                            {passwordError && (
                                <Typography color="error" sx={{ mt: 1 }}>
                                    {passwordError}
                                </Typography>
                            )}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                value={user.name}
                                onChange={handleChange}
                                autoComplete="name"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={user.email}
                                onChange={handleChange}
                                autoComplete="email"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: '#1976d2' }}
                            >
                                Sign Up
                            </Button>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Typography variant="body2" color="textSecondary">
                                    {'Already have an account? '}
                                    <Link href="/" variant="body2">
                                        {'Sign In'}
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Container>
            <Response response={response} />
        </div>
    );
};

export default Register;
