import { DataGrid } from '@mui/x-data-grid';
import React from 'react'

const Table = ({ rows, columns }) => {
    function getRowId(row) {
        if (!row.id) {
            return row[columns[0].field]
        } else {
            return row.id
        }
    }

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            getRowId={getRowId}
            autosizeOnMount={true}
            pageSizeOptions={[10]}
        />
    )
}

export default Table