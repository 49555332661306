import { useState, useEffect } from 'react'
import { Box, Container, IconButton, Typography, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import Table from '../components/Table'
import CreateForm from '../components/CreateForm'
import UpdateForm from '../components/UpdateForm'
import DeleteForm from '../components/DeleteForm'
import Api from '../configs/Api'
import { PageContainer } from '@toolpad/core'

const Warehouse = () => {
    const [warehouses, setWarehouses] = useState([])
    const [idToUpdate, setIdToUpdate] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)
    const [openCreateForm, setOpenCreateForm] = useState(null)
    const [openUpdateForm, setOpenUpdateForm] = useState(false)
    const [openDeleteForm, setOpenDeleteForm] = useState(false)
    const theme = useTheme()

    const [data, setData] = useState({
        name: '',
        phone: '',
        address: ''
    })

    const forms = [
        { label: 'Name', name: 'name' },
        { label: 'Phone', name: 'phone' },
        { label: 'Address', name: 'address' }
    ]

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'phone', headerName: 'Phone', flex: 1 },
        { field: 'address', headerName: 'Address', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton color="primary" onClick={() => handleOpenUpdateForm(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleOpenDeleteForm(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ]

    const title = 'WAREHOUSE'
    const endpoint = '/warehouses'

    useEffect(() => {
        getWarehouses()
    }, [])

    const getWarehouses = async () => {
        const res = await Api.Get(endpoint)
        setWarehouses(res.data.data)
    }

    const handleOpenCreateForm = () => {
        setData({
            name: '',
            phone: '',
            address: ''
        })
        setOpenCreateForm(true)
    }

    const handleCloseCreateForm = () => setOpenCreateForm(false)

    const handleOpenUpdateForm = (data) => {
        setData({
            name: data.name,
            phone: data.phone,
            address: data.address
        })
        setIdToUpdate(data.id)
        setOpenUpdateForm(true)
    }

    const handleCloseUpdateForm = () => setOpenUpdateForm(false)

    const handleOpenDeleteForm = (data) => {
        setIdToDelete(data.id)
        setOpenDeleteForm(true)
    }

    const handleCloseDeleteForm = () => setOpenDeleteForm(false)

    const handleRefreshData = () => {
        getWarehouses()
    }

    document.title = 'SUPPLIER - MASTER DATA - FXSTEEL'

    return (
        <PageContainer>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    width: '100%',
                }}
            >
                <Box></Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="primary"
                        onClick={handleRefreshData}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black', mr: 1 }}
                    >
                        <RefreshOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleOpenCreateForm}
                        sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>

            <CreateForm
                open={openCreateForm}
                onClose={handleCloseCreateForm}
                title={title}
                forms={forms}
                endpoint={endpoint}
                data={data}
                getDatas={getWarehouses}
            />

            <Table rows={warehouses} columns={columns} />

            <DeleteForm
                open={openDeleteForm}
                onClose={handleCloseDeleteForm}
                title={title}
                endpoint={endpoint}
                id={idToDelete}
                getDatas={getWarehouses}
            />

            <UpdateForm
                open={openUpdateForm}
                onClose={handleCloseUpdateForm}
                title={title}
                forms={forms}
                endpoint={endpoint}
                data={data}
                id={idToUpdate}
                getDatas={getWarehouses}
            />
        </PageContainer>
    )
}

export default Warehouse