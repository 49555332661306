import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Container } from "@mui/material"
import Response from "../components/Response";
import Api from "../configs/Api";

const DeleteForm = ({ open, onClose, title, endpoint, id, getDatas }) => {
    const [response, setResponse] = useState([])

    const handleDelete = async () => {
        if (!id) return;

        try {
            const res = await Api.Delete(`${endpoint}`, id);
            setResponse(res.data.data);
            getDatas();
            onClose()
        } catch (error) {
            setResponse(error.response.data.errors);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="confirm-delete-dialog-title"
            >
                <DialogTitle id="confirm-delete-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this {title.toLowerCase()}?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={onClose}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Response response={response} />
        </>
    )
}

export default DeleteForm