import { useEffect, useState } from "react"
import Api from "../configs/Api"
import Table from "../components/Table"
import { Dialog, CardContent, Grid, Divider, Typography, DialogContent, DialogTitle, Slide, Card } from "@mui/material"
import moment from "moment"

const AccountDetail = ({ open, onClose, account }) => {
    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        if (open) {
            if (account) {
                getTransactions()
            }
        }
    }, [open, account])

    const getTransactions = async () => {
        const res = await Api.Get(`/accounts/${account?.id}/transactions`)
        setTransactions(res.data.data)
    }

    const columns = [
        { field: 'createdAt', headerName: 'Date', flex: 1 },
        { field: 'type', flex: 1, headerName: 'Type' },
        {
            field: 'amount',
            flex: 1,
            headerName: 'Amount',
            renderCell: (params) => (
                'Rp ' + new Intl.NumberFormat('id-ID').format(params.value)
            )
        },
        { field: 'category', headerName: 'Category', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 }
    ]

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'lg'}
            fullWidth
            TransitionComponent={Slide}
            transitionDuration={500}
        >
            <DialogContent>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ textAlign: 'center', mb: 3 }}>
                                <Typography variant="h4">{account?.name}</Typography>
                                <Typography variant="h7">{account?.bank} &#x2022; {account?.number}</Typography>
                                <Divider sx={{ mt: 3 }} />
                            </Grid>
                            <Grid item xs={4} container textAlign="left">
                                <Grid item xs={3}>
                                    <Typography variant="body1">Balance</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body1">: &nbsp; Rp {new Intl.NumberFormat('id-ID').format(account?.balance)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container textAlign="left">
                                <Grid item xs={3}>
                                    <Typography variant="body1">Total Credit</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body1">: &nbsp; Rp {new Intl.NumberFormat('id-ID').format(account?.totalCredit)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container alignItems="left">
                                <Grid item xs={3}>
                                    <Typography variant="body1">Total Debit</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body1">: &nbsp; Rp {new Intl.NumberFormat('id-ID').format(account?.totalDebit)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Divider  sx={{ mb: 2}} />
                <Table rows={transactions} columns={columns} />
            </DialogContent>
        </Dialog>
    )
}

export default AccountDetail